
import { Routes } from "@/router";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "Pricing",
  methods: {
    setModal(componentName: string): void {
      this.$store.dispatch("setModal", componentName);
    },
    contactUs(): void {
      this.$router.push(Routes.CONTACT_US);
    },
  },
});
